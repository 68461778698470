<template>
  <div>
    <rxNavBar v-if="androidFlg == false" androidOrIOSFlag="true" title="房源列表"></rxNavBar>
    <rxNavBar v-else title="房源列表"></rxNavBar>
    <!--<van-nav-bar class="navbar" title="寻找房源" fixed left-arrow @click-left="leftReturn"/>-->
    <div class="head">
      <div class="headd">
        <div style="display: flex" @click="selectCity">
          <img class="cheeet" src="../../../assets/images/3.png">
          <div class="cheeec">{{ curCityName }}</div>
        </div>
        <div class="search">
          <div class="search-icon"></div>
          <input v-model="searchValue" placeholder="搜索小区，商圈" style="margin-left:-12px;width: 5rem" type="text"
                 @keyup.enter="onSearch">
        </div>
      </div>

      <div class="types">
        <div style="display: flex;align-items: center;justify-content: space-around">
          <van-cell is-link style="display: flex" @click="popup">
            <div id="downList" class="typef">{{ typeName }}</div>
            <div :class="isShow?'small-icon-rotate':'small-icon'"></div>
          </van-cell>


          <!--商圈下拉列表-->


          <!--                <div @click="popup">12345</div>-->
          <!--              <van-popup v-model="isShow" position="top" :style="{ height: '30%' }" />-->
        </div>
        <div class="content-divide-sa">|</div>
        <van-cell is-link style="display: flex" @click="showOrder">
          <div id="downOrderList" class="typef">{{ orderName }}</div>
          <div :class="isOrderShow?'small-icon-rotate':'small-icon'"></div>
        </van-cell>
        <van-popup v-model="isOrderShow" :style="{ height: '30%' }" position="top">
          <!--综合排序下拉列表-->
          <div v-show="isOrderShow" class="selectList">
            <div v-for="(itemOrder,index) in typeOrderList" :key="index" :class="{selectActive:index===number}"
                 class="select" @click="changeOrderType(index)">
              {{ itemOrder.reviewOrderType }}
            </div>
          </div>
        </van-popup>
        <div class="content-divide-sa">|</div>
        <van-cell is-link style="display: flex" @click="showIf">
          <div id="downIfList" class="typef">{{ sif }}</div>
          <div :class="isIfShow?'small-icon-rotate':'small-icon'"></div>
        </van-cell>
        <van-popup v-model="isIfShow" :style="{ width: '30%' }" position="right">

        </van-popup>

      </div>
    </div>

    <div>
      <van-popup v-model="maskShow" class="screen" position="right">
        <div style="height: 85%;overflow: scroll">
          <div class="screety">类型</div>
          <div class="sty">
            <span :class="{'bgcolor':-1==spanIndex}" class="stye" @click="changenos() ">不限</span>
            <span v-for="( item,index) in atypList" :key="index" :class="{'bgcolor':index==spanIndex}" class="stye"
                  @click="changeSpan(index)">{{ item.dictionaryTitle }}</span>
          </div>
          <div class="screety">价格</div>
          <div class="sty">
            <span :class="{'bgcolor':-1==pricecSIdex}" class="stye" @click="pricechangenos()">不限</span>
            <span v-for="(item,index) in priceList" :key="index" :class="{'bgcolor':index==pricecSIdex}"
                  class="styeprice"
                  @click="pricechangeSpan(index)">{{ item.dictionaryTitle }}</span>
          </div>
          <div class="screety">户型</div>
          <div class="sty">
            <span :class="{'bgcolor':-1==houseSIdex}" class="stye" @click="housechangenos()">不限</span>
            <span v-for="(item,index) in souseList" :key="index" :class="{'bgcolor':index==houseSIdex}"
                  class="styeprice"
                  @click="housechangeSpan(index)">{{ item.dictionaryTitle }}</span>
          </div>
          <!-- <div class="screety">期限</div>
           <div class="sty">
               <span class="stye" v-for="(item,index) in dateList" :key="index" @click="datechangeSpan(index)"
                     :class="{'bgcolor':index==dateSIdex}">{{item}}</span>
           </div> -->
          <div class="screety">房源特色</div>
          <div class="sty">
            <span :class="{'bgcolor':charaSnosIdex==-1}" class="stye" @click="charachangenos()">不限</span>
            <span v-for="(item,index) in charaList" :key="index" :class="{'bgcolor':charaSIdex.indexOf(index)>-1}" class="styeprice"
                  @click="charachangeSpan(index)">{{ item.dictionaryTitle }}</span>
          </div>
          <div class="screety">房源状态</div>
          <div class="sty">
            <span :class="{'bgcolor':stateSIdex==-1}" class="stye" @click="statechangenos()">不限</span>
            <span v-for="(item,index) in stateList" :key="index" :class="{'bgcolor':index==stateSIdex}" class="styeprice"
                  @click="statechangeSpan(index)">{{ item.name }}</span>
          </div>
          <div class="scrsty" style="width: 94%;position: absolute;bottom: 4%;z-index: 100">
            <div style="display: flex;align-items: center;white-space: nowrap">
              <div @click="cleanSpan()"><img class="clean-icon" src="../../../assets/images/4.png"></div>
              <div class="clean_button" @click="cleanSpan()">清空</div>

            </div>

            <div class="screen_button" @click="screenSpan()">筛选</div>
          </div>
        </div>

      </van-popup>
    </div>
    <div>
      <van-popup v-model="isShow" position="left" style="width:85%;height:100%">
        <div style="height: 100%;overflow: scroll">
          <div style="height: 35%;overflow: scroll">
            <!-- <div class="choose-company-texst">选择公司</div> -->
            <div class="screety">公司</div>
            <div class="sty">
                    <span v-for="(item,index) in companyList" :key="index" :class="
                              checkedIndex == index
                                ? 'company-col-checked-style'
                                : 'company-text-style'
                            "
                          class="styeprices"
                          @click="selectCompany(item, index)">{{
                        item.departmentName ? item.departmentName : '部门检索'
                      }}</span>
            </div>

          </div>
          <div style="height: 50%;">


            <div class="screety">部门</div>
            <van-tree-select
                :active-id.sync="activeId"
                :items="items"
                :main-active-index.sync="activeIndex"
                @click-nav="selectArea"
                @click-item="selectDepartment"
            />
          </div>

          <div class="searchButton">
            <div style="display: flex;align-items: center;white-space: nowrap">
              <div @click="resetDepartmentInfo()"><img class="clean-icon" src="../../../assets/images/4.png"></div>
              <div class="clean_button" @click="resetDepartmentInfo()">清空</div>

            </div>

            <div class="screen_button" @click="searchDepartmentInfo()">筛选</div>
          </div>


        </div>
      </van-popup>
    </div>
    <!--遮罩层-->
    <!--        <div id="light" class="white_content">-->
    <!--        </div>-->
    <!--        <div id="fade" class="black_overlay">-->
    <!--        </div>-->
    <!--        <div id="fadeorder" class="black_order_overlay">-->
    <!--        </div>-->
    <div class="blankWeight"></div>
    <!--        <div class="reviews">-->
    <!--寻找房源列表-->
    <div class="contracts">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
            v-model="loading"
            :finished="finished"
            :finished-text="isEmptuyFlag ? '' : '已经到底了'"
            @load="this.onLoad"
        >
          <div v-for="(item,index) in houseList" :key="index" class="detailsList" @click="jumpHouseList(index)">
            <div class="info" style="width: 40%">
              <img :src="item.roomPhotoPath?item.roomPhotoPath:house_image" class="partsdt">
            </div>

            <div class="infos" style="width: 60%;margin-left: 0.4rem;line-height: 0.5rem;">
              <div style="font-size: 0.4rem;color: #101010">{{ item.roomDetailedAddress }}</div>
              <div class="keyAddress">
                {{ item.roombusinessCircleName }}
                <span class="content-divide-dos">|</span>
                {{ item.roomArea }}m²
                <span class="content-divide-dos">|</span>
                {{ item.roomFloor }}层
              </div>

              <div style="display: flex;align-items: center;font-size: 0.4rem;color: #ff5d37;margin-top: 3px">
                <div class="money">￥{{ item.roomRent }}</div>
                <div class="month" style="font-size: 0.4rem">/月</div>
              </div>

              <div style="display: flex;align-items: center;margin-top: 5px;">
                <span class="dispose1" style="background-color: #DEEBFB;color: #86B3E8;">{{ item.subTypeStr }}{{ Number(item.vacantDays)+1>=-29?`(${Number(item.vacantDays)+1}天)`:'' }}</span>
                <div class="dispose">
                  <span v-show="item.houseType == 0" class="state">整租</span>
                  <span v-show="item.houseType == 1" class="state">合租</span>
                  <span v-show="item.houseType == 2" class="state">集寓</span>
                  <span v-show="item.houseType == 3" class="state">独家</span>
                  <span v-if="item.roomCharacteristicList">
                            <span v-if="item.roomCharacteristicList.length>= 1"
                                  class="statp">{{ item.roomCharacteristicList[0].roomCharacteristicName }}</span>
                            <span v-if="item.roomCharacteristicList.length>= 2"
                                  class="statp">{{ item.roomCharacteristicList[1].roomCharacteristicName }}</span>
                            <span v-if="item.roomCharacteristicList.length== 3"
                                  class="statp">{{ item.roomCharacteristicList[2].roomCharacteristicName }}</span>
                            </span>
                </div>

              </div>

            </div>
          </div>
          <common-empty v-if="isEmptuyFlag"></common-empty>
        </van-list>
        <!--空状态显示-->

      </van-pull-refresh>
    </div>

    <!--        </div>-->
    <!--<div class="addClientBtnDiv" >

    </div>  -->

  </div>
</template>

<script>
import {
  NavBar, Search, DropdownMenu,
  DropdownItem, Popup, Tag, Tabbar, TabbarItem, List, PullRefresh, Toast, Row, Col, TreeSelect, Button,
} from 'vant';
import Vue from 'vue';

Vue.use(List, PullRefresh);
import {
  browseRoomListStaff,
  queryBaseData,
  homePageRoomInit,
  seeWithList,
  queryCityList,
  queryAuthLevelDepartment
} from "../../../getData/getData";
import {responseUtil, getStaffId, globaluserId, checkAndroid, checkIOS,} from "../../../libs/rongxunUtil";
import rxTabbar from '../../../components/rongxun/rx-tabbar/tabbar'
import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
import eventBus from '../../../libs/eventBus.js'

export default {
  components: {
    [NavBar.name]: NavBar,
    [Search.name]: Search,
    [Popup.name]: Popup,
    [Tag.name]: Tag,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [TabbarItem.name]: TabbarItem,
    [Tabbar.name]: Tabbar,
    rxTabbar,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [NavBar.name]: NavBar,
    rxNavBar,
    [Col.name]: Col,
    [Row.name]: Row,
    [TreeSelect.name]: TreeSelect,
    [Button.name]: Button,
  },
  name: "houseList",
  data() {
    return {
      androidFlg: '',
      checkedIndex: 0,
      activeIndex: 0,
      items: [],
      // offset:'10',
      cityList: [],
      list111: [],
      roomFeatureList: [],
      searchValue: '',
      priceRange_id: '',
      roomType: '',
      houseChildType: '',
      apartment_id: '',
      currentPage: '1',
      city_id: '',
      totalPage: '',
      pageCount: '',
      loading: false,
      finished: false,
      isEmptuyFlag: false,
      refreshing: false,
      curCityName: '正在获取当前位置',
      house_image: require('../../../assets/images/house_image.png'),
      functionId: '394',
      active: 1,
      spanIndex: -1,
      pricecSIdex: -1,
      houseSIdex: -1,
      dateSIdex: 0,
      charaSnosIdex: -1,
      charaSIdex: [],

      stateSIdex: -1,
      maskShow: false,
      number: 0,
      typeName: '部门检索',
      type: '',
      order: '',
      orderName: '综合排序',
      sif: '条件筛选',
      typeList: [
        {reviewType: '大连海米', id: '52'}, {reviewType: '沈阳海米', id: "124"}, {
          reviewType: '遇见酒店',
          id: '121'
        }, {reviewType: '股东会', id: '182'},
      ],
      atypList: ['不限', '合租', '整租', '集寓'],
      priceList: ['不限', '1k-2K', '2k-3K', '2k-3K', '3k以上'],
      souseList: ['不限', '4室2厅1卫', '1室1厅1卫', '2室2厅', '2室2厅'],
      dateList: ['不限', '4-6个月', '6个月+'],
      charaList: ['不限', '空间大', '独卫', '独卫'],
      //{houseChildType:'1',name:'可租'},
      stateList: [{houseChildType: '2', name: '空置'}, {houseChildType: '6', name: '配置'}, {
        houseChildType: '3',
        name: '转租'
      },
        {houseChildType: '4', name: '预到期'}, {houseChildType: '7', name: '预定'}, {houseChildType: '8', name: '已租'},],
      typeOrderList: [
        {reviewOrderType: '默认排序', id: ''}, {reviewOrderType: '价格从低到高', id: '0'}, {
          reviewOrderType: '价格从高到低',
          id: '1'
        }, {reviewOrderType: '距离从近到远', id: '2'},
      ],
      isShow: false,
      isOrderShow: false,
      isIfShow: false,
      isWait: false,
      isAll: true,
      list: {
        content: ''
      },
      //寻房列表
      houseList: [],
      //模拟天通苑南的列表
      list1: [],
      //模拟天信花园的列表
      list2: [],
      //空的集合用来转换数据
      list0: [],
      houseCount: {total: '971', empty: '725', expire: '188', configure: '50', sublet: '8'},
      minPrice: '',
      maxPrice: '',
      longitude: '',
      latitude: '',


      companyList: [],
      departmentAuthData: {},
      departmentId: '',
      departmentName: '',
      activeId: ''

    }
  },
  mounted: function () {
    // 初始化
    //this.initData()

    this.init()
    // localStorage.setItem('searchKeyword', '')
    this.initQueryHouseData();
    this.queryAuthLevelDepartment()
    // this.selectCompany(this.companyList[0],0)
    this.checkPhoneorMobel()
  },
  activated() {
    //根据key名获取传递回来的参数，data就是map
    eventBus.$on('houseList', function (data) {
      // console.log(data,"data");
      this.curCityName = data.cityName
      this.city_id = data.city_id
      if (data.city_id) {
        this.currentPage = 1
        this.houseList = []
        this.initData()
      }

    }.bind(this));
  },

  methods: {
    checkPhoneorMobel() {
      if (checkAndroid()) {
        this.androidFlg = true
      } else if (checkIOS()) {
        this.androidFlg = false
      }
    },
    onLoad() {
      setTimeout(() => {
        // console.log( this.currentPage)
        // if (this.refreshing) {
        //   if(this.currentPage>1){
        //     this.currentPage=  this.currentPage-2;
        //   }else if(this.currentPage<=1){
        //     this.currentPage=  1
        //   }
        //
        //   this.refreshing = false;
        // }
        console.log(this.city_id, 'this.city_id=======')
        if (this.city_id) {
          this.initData()
        }
        // for (let i = 0; i < 10; i++) {
        //   this.houseList.push(this.houseList.length + 1);
        // }
        // this.loading = false;

        // if (this.houseList.length >= this.pageCount) {
        //   this.finished = true;
        // }
      }, 1000);
    },

    queryAuthLevelDepartment() {
      const that = this
      let initData = {}
      initData.staff_id = getStaffId() //员工id
      initData.function_id = that.functionId //方法模块id
      queryAuthLevelDepartment(initData).then(res =>
          responseUtil.dealResponse(that, res, () => {
            that.departmentAuthData = res.data.data
            console.log(that.departmentAuthData, 'that.departmentAuthData===')
            let allList = that.departmentAuthData.list
            if (allList != null) {
              that.companyList = allList.filter(item => item.level == 1)
            }
            let departmentId = that.departmentAuthData.roleDepartmentId
            that.departmentId = departmentId
            let item = that.departmentAuthData.list.find(
                item => item.id == departmentId
            )
            that.departmentName = item ? item.departmentName || '' : ''
            that.initOtherDepartment()
            // that.searchDepartmentInfo()

          })
      )
    },

    selectCompany(item, index) {
      console.log(item, "item===")
      let that = this
      let roleLevel = this.departmentAuthData.roleLevel || 0
      let roleDepartmentId = this.departmentAuthData.roleDepartmentId || ''
      if (roleDepartmentId != item.id && Number(item.level) < roleLevel) {
        this.isShow = false
        responseUtil.alertErrMsg(that, '权限不足')
        return
      }
      this.checkedIndex = index
      this.departmentId = item.id
      this.activeId = this.departmentId
      this.departmentName = item.departmentName
      this.initOtherDepartment()
    },
    initOtherDepartment() {
      let that = this
      let allList = that.departmentAuthData.list
      let departmentId = that.departmentId
      let threeLevelDepartments = allList.filter(item => item.level == 3)
      let fiveLevelDepartments = allList.filter(item => item.level == 5)
      let levelDepartments = threeLevelDepartments
          .filter(item => {
            let levelTop = item.levelTop || ''
            return levelTop.indexOf(departmentId) != -1
          })
          .map(item => {
            let result = {}
            result.children = fiveLevelDepartments
                .filter(child => {
                  let levelTop = child.levelTop || ''
                  let levelTop1 = child.departmentName
                  let levelTop2 = item.departmentName
                  return levelTop.indexOf(',' + item.id) != -1
                })
                .map(child => {
                  let childResult = {}
                  childResult.text = child.departmentName
                  childResult.id = child.id
                  childResult.level = child.level
                  return childResult
                })
            result.text = item.departmentName
            result.value = item.id
            result.level = item.level
            return result
          })
      that.items = levelDepartments // 公司列表
    },
    selectArea(value) {
      let item = this.items[value]
      let roleLevel = this.departmentAuthData.roleLevel || 0
      let roleDepartmentId = this.departmentAuthData.roleDepartmentId || ''
      if (roleDepartmentId != item.value && Number(item.level) < roleLevel) {
        this.isShow = false
        responseUtil.alertErrMsg(this, '权限不足')
        return
      }
      this.departmentId = item.value
      this.departmentName = item.text
      this.activeId = this.departmentId
      // console.log(this.departmentId,this.departmentName,'selectArea===')
    },
    selectDepartment(data) {
      let roleLevel = this.departmentAuthData.roleLevel || 0
      let roleDepartmentId = this.departmentAuthData.roleDepartmentId || ''
      if (roleDepartmentId != data.value && Number(data.level) < roleLevel) {
        this.isShow = false
        responseUtil.alertErrMsg(this, '权限不足')
        return
      }
      this.departmentId = data.id
      this.departmentName = data.text
      // console.log(this.departmentId,this.departmentName,'selectDepartment===')
    },
    resetDepartmentInfo() {
      // alert(111)
      let departmentId = this.departmentAuthData.roleDepartmentId
      let item = this.departmentAuthData.list.find(
          item => item.id == departmentId
      )
      this.departmentName = item ? item.departmentName || '' : ''
      this.activeId = ''
      this.departmentId = departmentId

      this.queryAuthLevelDepartment()
      this.checkedIndex = 0
      this.selectCompany(this.companyList[0], 0)
      // this.queryAuthLevelDepartment()
      // this.searchDepartmentInfo()
    },
    searchDepartmentInfo() {
      // this.number = index;
      // console.log(index);
      this.type = this.departmentId
      this.currentPage = 1
      this.houseList = []
      // this.onLoad()
      this.initData()
      this.isShow = false;
    },


    init() {
      let that = this
      queryCityList({}).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          that.list = response.data.data.cityList
          that.cityList = that.list
          that.autoGetCity()
        })
      })
    },
    selectCity() {
      this.$router.push({name: 'selectCity', query: {curCityName: this.curCityName}});
    },
    popup() {
      this.isShow = !this.isShow
    },
    //返回上一层
    leftReturn() {
      this.$router.go(-1);
    },
    // 页面初始化
    initData: function (e) {
      var that = this
      let initData = {}
      if (e) {
        // console.log(e)
        initData.user_id = getStaffId()
        initData.staff_id = getStaffId()
        initData.city_id = that.city_id
        initData.searchInfo = that.searchValue
        initData.currentPage = e.currentPage || 1
        initData.numberPage = 10
        initData.departmentId = that.type   //部门检索
        initData.seqType = that.order   //综合排序
        if (initData.seqType == '0' || initData.seqType == '1') {
          initData.seqField = '0'
        } else if (initData.seqType == '2') {
          initData.seqField = '1'
        }
        initData.minPrice = that.minPrice       //最低价格
        initData.maxPrice = that.maxPrice  //最高价格
        initData.apartment_id = e.apartment_id     //户型
        initData.priceRange_id = e.priceRange_id     //户型
        if (initData.priceRange_id == -1) {
          initData.priceRange_id = ''
        }
        if (initData.apartment_id == -1) {
          initData.apartment_id = ''
        }
        if (e.roomFeatureList) {
          initData.roomFeatureList = e.roomFeatureList
          //房源特色
        }
        if (e.houseChildType == '3') {
          initData.changeStatus = '1'
        } else {
          initData.changeStatus = ''
          initData.subType = e.houseChildType     //状态
          if (initData.subType == -1) {
            initData.subType = ''
          }
        }
        initData.longitude = that.longitude
        initData.latitude = that.latitude
        initData.roomType = e.roomType//'3'
        if (initData.roomType == -1) {
          initData.roomType = '3'
        }
      } else {
        initData.user_id = getStaffId()
        initData.staff_id = getStaffId()
        initData.city_id = that.city_id
        initData.searchInfo = that.searchValue
        initData.currentPage = that.currentPage || 1
        initData.numberPage = 10
        initData.departmentId = that.type   //部门检索
        initData.seqType = that.order   //综合排序
        if (initData.seqType == '0' || initData.seqType == '1') {
          initData.seqField = '0'
        } else if (initData.seqType == '2') {
          initData.seqField = '1'
        }
        initData.minPrice = that.minPrice       //最低价格
        initData.maxPrice = that.maxPrice  //最高价格
        initData.apartment_id = that.apartment_id     //户型
        initData.priceRange_id = that.priceRange_id     //户型
        if (initData.priceRange_id == -1) {
          initData.priceRange_id = ''
        }
        if (initData.apartment_id == -1) {
          initData.apartment_id = ''
        }
        if (that.roomFeatureList.length > 0) {
          initData.roomFeatureList = that.roomFeatureList
          //房源特色
        }//房源特色

        if (that.houseChildType == '3') {
          initData.changeStatus = '1'
        } else {
          initData.changeStatus = ''
          initData.subType = that.houseChildType    //状态
          if (initData.subType == -1) {
            initData.subType = ''
          }
        }
        initData.longitude = that.longitude
        initData.latitude = that.latitude
        initData.roomType = that.roomType//'3'
        if (initData.roomType == -1) {
          initData.roomType = '3'
        }
      }
      browseRoomListStaff(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          that.currentPage++;
          var count = response.data.data.pageCount
          let list = response.data.data.data
          if (list) {
            for (let i = 0; i < list.length; i++) {
              that.houseList.push(list[i])
            }
          }
          that.loading = false;
          if (that.refreshing) {
            that.refreshing = false
          }
          if (that.houseList.length >= count) {
            that.finished = true;
          }
          if (1 == that.page && !list) {
            that.isEmptuyFlag = true
          } else {
            that.isEmptuyFlag = false
          }
        })
      })
    },

    onRefresh() {
      // 清空列表数据
      this.spanIndex = -1;
      this.stateSIdex = -1
      this.houseChildType = ''
      this.minPrice = ''
      this.apartment_id = ''
      this.maxPrice = ''
      this.roomFeatureList = []
      this.pricecSIdex = -1;
      this.houseSIdex = -1;
      this.dateSIdex = 0;
      this.charaSIdex.splice(0, this.charaList.length);
      this.charaSnosIdex = -1;
      this.finished = false;
      this.houseList = []
      this.currentPage = 1
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.refreshing = false;
      this.onLoad();
      responseUtil.alertMsg(this, "刷新成功")


      // // 清空列表数据
      // this.finished = false;
      // // 重新加载数据
      // // 将 loading 设置为 true，表示处于加载状态
      // this.loading = true;
      // this.onLoad();
    },

    onSearch() {
      this.currentPage = 1
      this.houseList = []
      this.initData()
    },

    getCount() {
      let that = this
      let initData = {}
      initData.houseChildType = '1'
      initData.houseType = '3'
      initData.user_id = getStaffId()
      queryHouseType(initData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          console.log(response)
          //that.initParam.header = response.data.data
        })
      })
    },
    // 页面初始化
    initQueryHouseData: function () {
      var that = this
      let initQueryHouseData = {}
      initQueryHouseData.dbName = ["priceRange", "houseType", "housingSource"]
      initQueryHouseData.fdName = ["HOUSERETYPEMAP"]
      queryBaseData(initQueryHouseData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          that.atypList = response.data.data.HOUSERETYPEMAP
          that.priceList = response.data.data.priceRange
          that.souseList = response.data.data.houseType
          that.charaList = response.data.data.housingSource
          // console.log(that.souseList)
        })
      })
    },

    //商圈 下拉列表
    showOrHidden: function () {
      this.isShow = true;
      var sp = document.getElementById("downList");
      //点击后标头变换颜色
      document.getElementById('downList').style.color = '#ff5a37';
      //自定义显示遮罩
      document.getElementById('light').style.display = 'block';
      document.getElementById('fade').style.display = 'block'
      if (sp) {
        document.addEventListener('click', e => {
          if (!sp.contains(e.target)) {
            this.isShow = false;
            document.getElementById('downList').style.color = 'black';
            //关闭遮罩
            document.getElementById('light').style.display = 'none';
            document.getElementById('fade').style.display = 'none'
          }
        })
      }
    },
    //综合排序下拉列表
    showOrder: function () {

      this.isOrderShow = true;
      var sp = document.getElementById("downOrderList");
      // document.getElementById('downOrderList').style.color = '#ff5a37';
      //自定义显示遮罩
      document.getElementById('light').style.display = 'block';
      document.getElementById('fadeorder').style.display = 'block'
      if (sp) {
        document.addEventListener('click', e => {
          if (!sp.contains(e.target)) {
            this.isOrderShow = false;
            document.getElementById('downOrderList').style.color = 'black';
            //关闭遮罩
            document.getElementById('light').style.display = 'none';
            document.getElementById('fadeorder').style.display = 'none'
          }
        })
      }
    },
    autoGetCity() {
      let that = this
      var geolocation = new BMap.Geolocation();
      geolocation.getCurrentPosition(function (r) {
        if (this.getStatus() == BMAP_STATUS_SUCCESS) {
          var city = r.address.city//返回当前城市
          that.curCityName = city
          that.longitude = r.longitude
          that.latitude = r.latitude
          let city_id = ''
          for (let i = 0; i < that.cityList.length; i++) {
            if (that.cityList[i].cityName + '市' == city) {
              city_id = that.cityList[i].city_id
            }
          }
          if (city_id) {
            that.city_id = city_id
            that.initData()
          }

        }
      })
    },
    //条件筛选下拉列表
    showIf: function () {
      this.maskShow = true;
    },
    changeType: function (index) {
      this.isShow = false;
      this.number = index;
      console.log(index);
      this.type = this.typeList[index].id;
      /*switch (index) {
          case 0:
              //this.houseList = this.list0;
              break;
          case 1:
              //this.houseList = this.list1;
              break;
          case 2:
              //this.houseList = this.list2;
              break;
          case 3:
              //this.houseList = this.list3;
              break;
      }*/
      this.currentPage = 1
      this.houseList = []
      this.onLoad()
    },

    changeOrderType: function (index) {
      this.isOrderShow = false;
      this.number = index;
      console.log(index)
      console.log(this.typeOrderList[index]);
      this.order = this.typeOrderList[index].id;
      switch (index) {
        case 0:
          this.houseList = this.list0;
          break;
        case 1:
          this.houseList.sort((a, b) => a['money'] > b['money'] ? 1 : -1);
          break;
        case 2:
          this.houseList.sort((a, b) => b['money'] > a['money'] ? 1 : -1);
          break;
        case 3:
          this.houseList = this.list0;
          break;
      }
      this.currentPage = 1
      this.houseList = []
      this.onLoad()
    },
    changenos: function () {
      this.spanIndex = -1;
    },
    changeSpan: function (index) {
      console.log(index)
      this.spanIndex = index
    },
    pricechangenos: function (index) {
      this.pricecSIdex = -1;
    },
    pricechangeSpan: function (index) {
      this.pricecSIdex = index;
    },
    housechangenos: function () {
      this.houseSIdex = -1;
    },
    housechangeSpan: function (index) {
      this.houseSIdex = index;
    },
    datechangeSpan: function (index) {
      this.dateSIdex = index;
    },
    charachangeSpan: function (index) {
      this.charaSnosIdex = 0
      let arrIndex = this.charaSIdex.indexOf(index);
      // console.log(arrIndex);
      if (arrIndex > -1) {
        this.charaSIdex.splice(arrIndex, 1);
      } else {
        this.charaSIdex.push(index);
      }
    },
    charachangenos: function () {
      this.charaSnosIdex = -1;
      this.charaSIdex.splice(0, this.charaList.length);
    },
    statechangeSpan(index) {
      this.stateSIdex = index
    },
    statechangenos: function () {
      this.stateSIdex = -1;
    },
    cleanSpan: function () {
      this.spanIndex = -1;
      this.stateSIdex = -1
      this.houseChildType = ''
      this.minPrice = ''
      this.apartment_id = ''
      this.maxPrice = ''
      this.roomFeatureList = []
      this.pricecSIdex = -1;
      this.houseSIdex = -1;
      this.dateSIdex = 0;
      this.charaSIdex.splice(0, this.charaList.length);
      this.charaSnosIdex = -1;

    },
    screenSpan: function () {
      this.houseList = []
      let initData = {}
      initData.currentPage = 1
      initData.numberPage = 10
      // 房源类型
      if (this.spanIndex != -1) {
        initData.roomType = this.atypList[this.spanIndex].dictionaryValue
        this.roomType = this.atypList[this.spanIndex].dictionaryValue
      }
      // 价格范围id
      if (this.pricecSIdex != -1) {
        initData.priceRange_id = this.priceList[this.pricecSIdex].id
        this.priceRange_id = this.priceList[this.pricecSIdex].id
      }
      // 户型id
      if (this.houseSIdex != -1) {
        initData.apartment_id = this.souseList[this.houseSIdex].id
        this.apartment_id = this.souseList[this.houseSIdex].id
      }
      //状态
      if (this.stateSIdex != -1) {
        initData.houseChildType = this.stateList[this.stateSIdex].houseChildType
        this.houseChildType = this.stateList[this.stateSIdex].houseChildType
      } else {
        initData.houseChildType = ''
        this.houseChildType = ''
      }
      // 房源特色列表

      if (this.charaSnosIdex == 0) {
        var a;
        initData.roomFeatureList = [];
        for (var i = 0; i < this.charaSIdex.length; i++) {
          a = this.charaSIdex[i];
          initData.roomFeatureList.push(this.charaList[a].id)
          // this.roomFeatureList.push(this.charaList[a].id)
        }
        console.log(initData.roomFeatureList)
        this.roomFeatureList = initData.roomFeatureList
      }
      this.currentPage = 1
      this.numberPage = 10
      this.finished = false;
      this.initData(initData);
      this.maskShow = false;
    },
    jumpHouseList: function (index) {
      //  console.log("ss="+ this.houseList[index].room_id)
      this.$router.push({
        name: 'houseDetails',
        query: {
          room_id: this.houseList[index].room_id,
          roomStatus: this.houseList[index].subType,
          roomStatusStr: this.houseList[index].subTypeStr,

        }
      });
    },
  }
}
</script>

<style scoped>
.blankWeight {
  height: 100px;
}

.contracts {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.detailsList {
  width: 93%;
  /*height: auto;*/
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.26rem;
}

.navbar {
  z-index: 10;
  background-color: #FFFFFF;
}

.dispose {
  width: 40px;
  /*box-sizing: border-box;*/
  /*padding: 0 5px;*/
  height: 18px;
  background-color: rgba(233, 91, 46, 0.2);
  color: rgba(255, 93, 59, 1);
  /*font-weight: 500;*/
  font-size: 0.26rem !important;
  text-align: center;
  border-radius: 2px;
  /*padding: 1px 2px;*/
  margin-right: 8px;
}
.dispose1{
  box-sizing: border-box;
  padding: 0 7px;
  height: 18px;
  background-color: rgba(233, 91, 46, 0.2);
  color: rgba(255, 93, 59, 1);
  font-size: 0.26rem !important;
  text-align: center;
  border-radius: 2px;
  margin-right: 8px;

}

.head {
  width: 100%;
  position: fixed;
  z-index: 8;
  overflow: hidden;
  background: #FFFFFF;

}

.headd {
  padding: 10px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search {
  /*margin: 60px 20px 0 0;*/
  width: 80%;
  height: 35px;
  border-radius: 30px;
  background-color: #f4f4f4;
  display: flex;
  align-items: center;
}

.cheeec {
  margin-right: 10px;
  font-size: 15px;
  font-weight: 500;

}

/*.cheeep {*/
/*    margin-right: 10px;*/
/*    width: 5px;*/
/*    height: 25px;*/

/*}*/

.cheeet {
  margin-right: 10px;
  width: 27px;
  height: 20px;

}

.search-icon {
  width: 25px;
  height: 25px;
  margin: 10px;
  background-image: url("../../../assets/images/search-icon.png");
  background-size: 80%;
  background-position: center;
  background-repeat: no-repeat;
}

input {
  font-size: 14px;
  margin-left: 15px;
  background-color: #f4f4f4;
  border: 0;
  width: 120px;
}

input::-webkit-input-placeholder {
  color: #d9d9d9;
}

.types {
  /*width: 100%;*/
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.typef {
  margin-right: 10px;
  font-size: 13px;
  color: #000000;
  font-weight: bold;

}

.order {
  /*width: 80px;*/
  /*text-align: right;*/
  /*height: 15px;*/
  margin-right: 10px;
  font-size: 13px;
  color: #000000;
  font-weight: bold;
  /*margin: 15px 0 0 10px;*/
}

.sif {
  /*width: auto;*/
  /*height: 15px;*/
  margin-right: 10px;
  font-size: 13px;
  color: #000000;
  font-weight: bold;
  /*margin: 15px 0 0 21px;*/
}

.content-divide-sa {
  font-size: 14px;
  /*margin: 15px 0 0 35px;*/
  color: #f4f4f4;
}

.content-divide-ac {
  font-size: 14px;
  /*margin: 15px 0 0 25px;*/
  color: #f4f4f4;
}

.content-divide-dos {
  font-size: 14px;
  margin: 15px 0 0 5px;
  color: #999999;
}

.keyAddress {
  font-size: 12px;
  /*height: auto;*/
  padding-top: 3px;
  /*padding-left: 17px;*/
  color: #7d7b7b;
}

.details .infos .zu .state {
  width: 125px;
  height: 50px;
  background-color: rgb(255, 221, 220);
  color: rgb(255, 91, 62);
  font-size: 10px;
  text-align: center;
  border-radius: 2px;
  padding: 1px 2px;
  margin-right: 8px;
}

.details .infos .zu .statp {
  width: 125px;
  background-color: #DEEBFB;
  color: #86B3E8;
  font-size: 10px;
  text-align: center;
  border-radius: 2px;
  padding: 1px 2px;
  margin-right: 8px;
}

.screety {
  font-size: 13px;
  font-weight: bold;
  /*margin-left: 20px;*/
  /*margin-top: 25px;*/
  margin: 20px;
}

.sty {
  margin-left: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: start;
}

.stye {
  white-space: nowrap; /*强制span不换行*/
  display: inline-block; /*将span当做块级元素对待*/
  overflow: hidden; /*超出宽度部分隐藏*/
  text-overflow: ellipsis; /*超出部分以点号代替*/
  line-height: 0.9; /*数字与之前的文字对齐*/
  color: #999999;
  font-size: 12px;
  text-align: center;
  padding: 7px 12px;
  width: 24%;
  /*margin-right: 6px;*/
}

.styeprice {
  white-space: nowrap; /*强制span不换行*/
  display: inline-block; /*将span当做块级元素对待*/
  overflow: hidden; /*超出宽度部分隐藏*/
  text-overflow: ellipsis; /*超出部分以点号代替*/
  line-height: 0.9; /*数字与之前的文字对齐*/
  color: #999999;
  font-size: 12px;
  text-align: center;
  padding: 7px 12px;
  width: 24%;
  /*margin-right: 16px;*/
}


.styehouse {
  width: 125px;
  color: #999999;
  font-size: 12px;
  text-align: center;
  padding: 5px 12px;
}

.bgcolor {
  background: rgb(255, 221, 220);
  color: rgb(255, 91, 62);
  font-size: 12px;
  text-align: center;
  border-radius: 5px;
  width: 24%;
  /*display: flex;*/
  /*align-items: center;*/
  /*justify-content: center;*/
}

.small-icon {
  width: 8px;
  height: 8px;
  margin: 5px 0 0 -5px;
  background-image: url("../../../assets/images/small-icon.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.small-icon-rotate {
  width: 8px;
  height: 8px;
  /*margin: 20px 0 0 5px;*/
  background-image: url("../../../assets/images/small-icon.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  transform: rotate(180deg);
}

.selectList {
  position: fixed;
  z-index: 9;
  width: 100%;
  height: 220px;
  top: 8%;
  padding: 5px 0 14px 0;
  background-color: white;
}

.select {
  width: 100%;
  height: 35px;
  line-height: 30px;
  font-size: 14px;
  text-align: left;
  margin-left: 15px;
  color: #999999;
}

.selectActive {
  color: #fe5e3a;
}

.reviews {
  width: 100%;
  overflow: visible;
  height: auto;
  position: absolute;
  top: 22%;
  background-color: #FFFFFF;
}

.review {
  margin: 0 15px 15px 15px;
  height: auto;
  background-color: #FFFFFF;
  border-radius: 8px;
  overflow: hidden;
}

.position {
  color: rgba(34, 34, 34, 1);
  font-weight: 600;
  font-family: PingFangSC-Regular;
  margin: 1px 15px 0 16px;
  height: 20px;
  line-height: 20px;
  font-size: 15px;
  text-align: left;
  overflow: hidden; /*超出部分隐藏*/
  white-space: nowrap; /*不换行*/
}

.details {
  width: 98%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

/*.details .info {
    width: 110px;
    height: auto;
    text-align: center;
    margin: 12px 8px 0 8px;

}*/

/*控制图片*/
.partsdt {
  width: 130px;
  height: 95px;
  fit: contain;
  border-radius: 10px;
}

/*.details .infos {
    padding-top: 0px;
    width: auto;
    height: auto;
    margin: 0 8px 0 8px;
}*/

.details .info .order {
  width: 100%;
  height: 20px;
  font-size: 14px;
  line-height: 20px;
  color: #999999;

}

.details .infos .mam .money {
  width: 50px;
  padding-top: 7px;
  padding-left: 15px;
  font-size: 13px;
  color: #ff5d37;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: bold;
  -webkit-transform: scale(1, 1.5);
  float: left;

}

.details .infos .mam .month {
  padding-top: 7px;
  padding-left: 15px;
  font-weight: 400;
  color: #fe5e3a;
  -webkit-transform: scale(1.1, 1.5);

  font-size: 8px;


}

.details .infos .zu {
  padding-left: 16px;
  transform: translateY(-20%);
  margin-top: 10px;
}

.screen {
  width: 80%;
  height: 100%;
  padding-top: 30px;

}

.scrsty {
  display: flex;
  align-items: center;
  justify-content: space-between;

}

/*垃圾桶*/
.clean-icon {
  height: 20px;
  /*padding: 0 5px 0 23px;*/
  margin-right: 10px;
  margin-left: 10px;
  width: 16px;
}

/*清空*/
.clean_button {
  /*position: absolute;*/
  /*top: 83px;*/
  font-size: 14px;
  color: rgba(255, 93, 59, 1);
}

/*筛选按钮*/
.screen_button {
  /*position: absolute;*/
  /*left: 150px;*/
  /*padding: 11px 31px;*/
  width: 80px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  border-radius: 6px;
  color: rgb(255, 255, 255);
  border: none;
  background: linear-gradient(rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
  background: -webkit-linear-gradient(left, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
  /* Safari 5.1 - 6.0 */
  background: -o-linear-gradient(right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
  /* Opera 11.1 - 12.0 */
  background: -moz-linear-gradient(right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
  /* Firefox 3.6 - 15 */
  background: linear-gradient(to right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
}

/*遮罩*/
.black_overlay {
  display: none;
  position: absolute;
  top: 38%;
  left: 0%;
  width: 100%;
  height: 62%;
  background-color: black;
  z-index: 1001;
  -moz-opacity: 0.8;
  opacity: .80;
  filter: alpha(opacity=88);
}

/*遮罩*/
.black_order_overlay {
  display: none;
  position: absolute;
  top: 45%;
  left: 0%;
  width: 100%;
  height: 55%;
  background-color: black;
  z-index: 1001;
  -moz-opacity: 0.8;
  opacity: .80;
  filter: alpha(opacity=88);
}

.white_content {
  display: none;
  position: absolute;
  width: 0%;
  height: 0%;
  background-color: white;
  z-index: 1002;
  overflow: auto;
}

/*添加潜客的按钮*/
.addClientBtnDiv {
  background-color: #fff;
  position: fixed;
  width: 100%;
  height: 70px;
  overflow: hidden;
  bottom: 0;
}

.choose-company-texst {
  font-size: 0.4rem;
  font-weight: 600;
  color: black;
  padding: 10px 0 0 5px;
  font-size: 18px;
}

.companyListShow {
  display: flex;
  justify-content: center;
  align-items: center;
}

/*公司字体样式 未选中*/
.company-col-style {
  margin-top: 2px;
  margin-bottom: 2px;
  line-height: 30px;
  margin-left: 1px;
  background: #86B3E8;
}

/*公司字体样式 被选中*/
.company-col-checked-style {

  /* text-align: center;
  background-color: #d3d3d3;
  border-radius: 1px;
  font-size: 0.32rem; */
  /* background: rgb(255, 221, 220);
  color: rgb(255, 91, 62); */
  background: rgb(255, 221, 220);
  color: rgb(255, 91, 62);

  border-radius: 5px;
  /* margin-left: 10px; */

  /* transform: rotate(180deg); */
}

/*公司字体样式*/
.company-text-style {

  /* text-align: center; */
  /*background-color:#f7f8fa;*/

  /* margin-left: 10px; */
  color: #999999;
  /* transform: rotate(180deg); */

}

.styeprices {
  white-space: nowrap; /*强制span不换行*/
  display: inline-block; /*将span当做块级元素对待*/
  overflow: hidden; /*超出宽度部分隐藏*/
  text-overflow: ellipsis; /*超出部分以点号代替*/
  line-height: 0.9; /*数字与之前的文字对齐*/
  /* color: #999999; */
  font-size: 12px;
  /* text-align: center; */
  padding: 7px 12px;
  width: 40%;
  /*margin-right: 16px;*/
}

.searchButton {
  margin: 15px 10px;
  display: flex;
  justify-content: space-between;
}
</style>
